import React from 'react'

import ConciergeIcon from 'components/blocks/ConciergeIcon'

import {
  Props,
  Theme,
} from './types'

import { Icon } from './styles'

const InsuranceTypeIcon: React.FC<Props> = ({
  theme,
  title,
  ...props
}): React.ReactElement => (theme === Theme.concierge && <ConciergeIcon title={title} />) || <Icon {...props} theme={theme} />

export * from './types'

export default InsuranceTypeIcon
