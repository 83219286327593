import { Theme as InsuranceTypeIconTheme } from 'components/base/Icon/InsuranceTypeIcon'

import { ProductKey } from './types'

export const products: Record<ProductKey, { to?: string, theme: InsuranceTypeIconTheme, title: string }> = {
  home: {
    to: '/products/homeowners-insurance',
    theme: InsuranceTypeIconTheme.home,
    title: 'Home',
  },
  auto: {
    to: '/products/auto-insurance',
    theme: InsuranceTypeIconTheme.auto,
    title: 'Auto',
  },
  life: {
    to: '/products/life-insurance',
    theme: InsuranceTypeIconTheme.life,
    title: 'Life',
  },
  health: {
    theme: InsuranceTypeIconTheme.health,
    title: 'Health & Medicare',
  },
  business: {
    to: '/products/business-insurance',
    theme: InsuranceTypeIconTheme.business,
    title: 'Business',
  },
  retirement: {
    to: '/products/investments-retirement',
    theme: InsuranceTypeIconTheme.retirement,
    title: 'Retirement',
  },
}
