export enum Theme {
  auto = 'auto',
  business = 'business',
  concierge = 'concierge',
  disability = 'disability',
  health = 'health',
  home = 'home',
  life = 'life',
  retirement = 'retirement',
  medicare = 'medicare',

  // Carrier Group Theme
  default = 'default',
  aetna = 'aetna',
  americanEquity = 'american-equity',
  americanReliable = 'american-reliable',
  amTrust = 'am-trust',
  asi = 'asi',
  assurity = 'assurity',
  athene = 'athene',
  bluecross = 'bluecross',
  cigna = 'cigna',
  cna = 'cna',
  equitrust = 'equitrust',
  fidelity = 'fidelity',
  foremost = 'foremost',
  guggenheim = 'guggenheim',
  hartford = 'hartford',
  healthnet = 'healthnet',
  hiscox = 'hiscox',
  humana = 'humana',
  iac = 'iac',
  johnhancock = 'johnhancock',
  kaiser = 'kaiser',
  kemper = 'kemper',
  kemperSpecialtyAuto = 'kemper-specialty-auto',
  libertyMutual = 'liberty-mutual',
  lifemap = 'lifemap',
  lincoln = 'lincoln',
  lumico = 'lumico',
  mapfre = 'mapfre',
  metlife = 'metlife',
  moda = 'moda',
  mutual = 'mutual',
  nationwide = 'nationwide',
  ohioNational = 'ohio-national',
  pacificsource = 'pacificsource',
  philadelphia = 'philadelphia',
  progressive = 'progressive',
  protective = 'protective',
  providence = 'providence',
  prudential = 'prudential',
  redshield = 'redshield',
  regence = 'regence',
  safeco = 'safeco',
  stillwater = 'stillwater',
  symetra = 'symetra',
  transamerica = 'transamerica',
  travelers = 'travelers',
  united = 'united',
  usli = 'usli',
}

export interface Props {
  title?: React.ReactElement | string,
  theme?: Theme,
  hasMargin?: boolean,
  colored?: boolean,
}
