import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

export const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
`

export const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const Product = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
  span {
    font-family: ${FONTS.FAMILYSECONDARY};
    font-size: 26px;
    font-weight: bold;
    margin-top: 20px;
    display: block;
  }
  ${MEDIA.medium`
    margin: 20px;
  `}
`

export const FooterWrapper = styled.div`
  margin-top: 20px;
  ${MEDIA.medium`
    margin-top: 40px;
  `}
`
