import styled from 'styled-components'

import Auto from 'assets/images/icons/insurance-types/Auto.svg'
import AutoColor from 'assets/images/icons/insurance-types/AutoColor.svg'
import Business from 'assets/images/icons/insurance-types/Business.svg'
import BusinessColor from 'assets/images/icons/insurance-types/BusinessColor.svg'
import Disability from 'assets/images/icons/insurance-types/Disability.svg'
import DisabilityColor from 'assets/images/icons/insurance-types/DisabilityColor.svg'
import Health from 'assets/images/icons/insurance-types/Health.svg'
import HealthColor from 'assets/images/icons/insurance-types/HealthColor.svg'
import Home from 'assets/images/icons/insurance-types/Home.svg'
import HomeColor from 'assets/images/icons/insurance-types/HomeColor.svg'
import Life from 'assets/images/icons/insurance-types/Life.svg'
import LifeColor from 'assets/images/icons/insurance-types/LifeColor.svg'
import Medicare from 'assets/images/icons/insurance-types/Medicare.svg'
import MedicareColor from 'assets/images/icons/insurance-types/MedicareColor.svg'
import Retirement from 'assets/images/icons/insurance-types/Retirement.svg'
import RetirementColor from 'assets/images/icons/insurance-types/RetirementColor.svg'

import {
  Props,
  Theme,
} from './types'

/* eslint-disable import/prefer-default-export */
export const Icon = styled.span<Props>`
  display: inline-block;
  height: 210px;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  transition: all 0.2s ease-in;
  margin-bottom: ${(props): string => (props.hasMargin ? '20px' : '0')};

  ${(props): string | undefined => (props.theme === Theme.auto && `
    background-image: ${props.colored ? `url(${AutoColor})` : `url(${Auto})`};
  `) || (props.theme === Theme.business && `
    background-image: ${props.colored ? `url(${BusinessColor})` : `url(${Business})`};
  `) || (props.theme === Theme.disability && `
    background-image: ${props.colored ? `url(${DisabilityColor})` : `url(${Disability})`};
  `) || (props.theme === Theme.health && `
    background-image: ${props.colored ? `url(${HealthColor})` : `url(${Health})`};
  `) || (props.theme === Theme.home && `
    background-image: ${props.colored ? `url(${HomeColor})` : `url(${Home})`};
  `) || (props.theme === Theme.life && `
    background-image: ${props.colored ? `url(${LifeColor})` : `url(${Life})`};
  `) || (props.theme === Theme.retirement && `
    background-image: ${props.colored ? `url(${RetirementColor})` : `url(${Retirement})`};
  `) || (props.theme === Theme.medicare && `
    background-image: ${props.colored ? `url(${MedicareColor})` : `url(${Medicare})`};
  `) || undefined}
`
