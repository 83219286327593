import React from 'react'

import Link, { Theme as LinkTheme } from 'components/base/Link'
import InsuranceTypeIcon from 'components/base/Icon/InsuranceTypeIcon'

import {
  Wrapper,
  Products,
  Product,
  FooterWrapper,
} from './styles'
import { Props } from './types'
import { products as allProducts } from './products'

type ProductKey = keyof typeof allProducts

const ProductGroup: React.FC<Props> = ({
  footer = null,
  header = null,
}): React.ReactElement => (
  <Wrapper>
    {header}
    <Products>
      {Object.keys(allProducts).map(product => {
        const productKey = product as ProductKey
        const productData = allProducts[productKey]

        return (
          <Product key={productData.title}>
            {productData.to ? (
              <Link theme={LinkTheme.unstyled} to={productData.to}>
                <InsuranceTypeIcon theme={productData.theme} colored={true} />
                <span>{productData.title}</span>
              </Link>
            ) : (
              <>
                <InsuranceTypeIcon theme={productData.theme} colored={true} />
                <span>{productData.title}</span>
              </>
            )}
          </Product>
        )
      })}
    </Products>
    {footer && <FooterWrapper>{footer}</FooterWrapper>}
  </Wrapper>
)

export default ProductGroup
